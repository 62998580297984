import * as React from "react";
import { graphql } from "gatsby";
import { Seo } from "../../components/Seo";
import { Header, Footer } from "../../components/layout/index";
import {
  Hero,
  KeyFigures,
  Features,
  Testimonials,
  References,
  BlogListing,
  Cta,
  KeyPoints,
  Partners,
  InteropRoles,
  ImageFeatureSection,
} from "../../components/sections/index";
import { urlFor } from "../../utils";

const Interop = props => {
  const { data } = props;

  const page = (data || {}).interopPage;
  const coverPictureURL = (page.hero && urlFor(page.hero.image)) || undefined; // let SEO component define default image if hero has no image

  return (
    <>
      {/* TODO move to sanity */}
      <Seo
        title="L'interopérabilité : un enjeu crucial pour Omnidoc"
        description="La téléexpertise est un pont entre les professionnels de santé et donc entre leurs systèmes d’information. L'interopérabilité entre Omnidoc et les autres outils qu'ils utilisent au quotidien est un levier essentiel pour fluidifier la circulation de l’information."
        keywords="téléexpertise médicale rémunérée télé-expertise teleexpertise tele-expertise expertise acte avis télé-dermatologie télédermatologie télé-cardiologie télécardiologie télémédecine télé-médecine assurance maladie interopérabilité Omnidoc"
        image={coverPictureURL}
      />
      <Header />
      {page.hero && (
        <Hero
          title={page.hero.title}
          accentTitle={page.hero.accentTitle}
          subtitle="UNE SOLUTION INTERCONNECTÉE" // TODO: move to sanity
          description={page.hero.description}
          image={page.hero.image}
        />
      )}
      {page.interopRolesSection && (
        <InteropRoles tabs={page.interopRolesSection.tabs} />
      )}
      {page.featuresSection && (
        <ImageFeatureSection featuresSection={page.featuresSection} />
      )}
      {page.partners && (
        <Partners
          title={page.partners.title}
          description={page.partners.description}
          partners={page.partners}
          useLargeLogos
        />
      )}
      {page.testimonials && page.testimonials.length > 0 && (
        <Testimonials testimonials={page.testimonials} />
      )}
      <Footer />
    </>
  );
};

export default Interop;

export const query = graphql`
  query InteropPageQuery {
    interopPage: sanityInteropPage(_id: { regex: "/(drafts.|)interopPage/" }) {
      hero {
        title
        accentTitle
        description
        image {
          ...Image
          alt
        }
      }
      interopRolesSection {
        tabs {
          title
          description
          href
          image {
            ...Image
            alt
          }
        }
      }
      featuresSection {
        title
        description
        image {
          ...Image
          alt
        }
        features {
          title
          image {
            ...Image
            alt
          }
          _rawDescription
        }
      }
      partners {
        title
        description
        logos {
          ...Image
          alt
          url
        }
      }
      testimonials {
        quote
        authorName
        authorTitle
        image {
          ...Image
          alt
        }
      }
    }
  }
`;
